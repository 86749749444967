import { useEffect, useState } from 'react';

import {
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';

import parseCourseEnrollmentsQuery from '@crehana/web/Graphql/Parsers/parseCourseEnrollmentsQuery';
import COURSE_ENROLLMENTS_QUERY from '@crehana/web/Graphql/Queries/CourseEnrollmentsQuery.graphql';
import { CourseEnrollment } from '@crehana/web/Graphql/types';

import { DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES } from './constants';
import {
  CourseEnrollmentsQueryResponse,
  CourseEnrollmentsQueryVariables,
} from './types';

const useLazyCourseEnrollments = (
  variables?: Partial<CourseEnrollmentsQueryVariables>,
): [
  (options?: QueryLazyOptions<CourseEnrollmentsQueryVariables>) => void,
  Omit<
    LazyQueryResult<
      CourseEnrollmentsQueryResponse,
      CourseEnrollmentsQueryVariables
    >,
    'data'
  > & {
    totalEnrollmentsCount: number;
    totalCount: number;
    courseEnrollments: CourseEnrollment[];
    onRefetch: (refetchVariables?: CourseEnrollmentsQueryVariables) => void;
  },
] => {
  const [
    courseEnrollmentsLazyQuery,
    { data, refetch, ...courseEnrollmentsQuery },
  ] = useLazyQuery<
    CourseEnrollmentsQueryResponse,
    CourseEnrollmentsQueryVariables
  >(COURSE_ENROLLMENTS_QUERY, {
    variables: {
      ...DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES,
      ...variables,
    },
    notifyOnNetworkStatusChange: true,
  });
  const totalCount = data?.me?.enrollmentSet?.totalCount || 0;

  const [totalEnrollmentsCount, setTotalEnrollmentsCount] = useState(0);

  useEffect(() => {
    if (totalCount > totalEnrollmentsCount) {
      setTotalEnrollmentsCount(totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const onRefetch = (refetchVariables?: CourseEnrollmentsQueryVariables) => {
    if (refetch) {
      refetch(refetchVariables);
    }
  };

  return [
    courseEnrollmentsLazyQuery,
    {
      // Return the current totalCount if totalEnrollmentsCount is not set yet
      totalEnrollmentsCount: totalEnrollmentsCount || totalCount,
      totalCount,
      courseEnrollments: parseCourseEnrollmentsQuery(data),
      onRefetch,
      refetch,
      ...courseEnrollmentsQuery,
    },
  ];
};

export default useLazyCourseEnrollments;
