import type { TPageTranslations } from '@crehana/i18n';

import { COURSES_TRANSLATION_KEY } from '../constants';
import type { CoursesLocaleKeys } from '../types';

const localeEn: TPageTranslations<
  CoursesLocaleKeys,
  typeof COURSES_TRANSLATION_KEY
> = {
  [COURSES_TRANSLATION_KEY]: {
    // Filters
    UDE_FILTER_ALL_TITLE: 'You have {{count}} course in total',
    UDE_FILTER_ALL_TITLE_plural: 'You have {{count}} courses in total',
    UDE_FILTER_ASSIGNED_TITLE: 'You have {{count}} assigned course',
    UDE_FILTER_ASSIGNED_TITLE_plural: 'You have {{count}} assigned courses',
    UDE_FILTER_INITIAL_TITLE: 'You have {{count}} course to start',
    UDE_FILTER_INITIAL_TITLE_plural: 'You have {{count}} courses to start',
    UDE_FILTER_PROGRESS_TITLE: 'You have {{count}} course in progress',
    UDE_FILTER_PROGRESS_TITLE_plural: 'You have {{count}} courses in progress',
    UDE_FILTER_COMPLETED_TITLE: 'You have {{count}} completed course',
    UDE_FILTER_COMPLETED_TITLE_plural: 'You have {{count}} completed courses',
    UDE_FILTER_HIDDEN_TITLE: 'You have {{count}} hidden course',
    UDE_FILTER_HIDDEN_TITLE_plural: 'You have {{count}} hidden courses',
    UDE_FILTER_ALL_LABEL: 'All',
    UDE_FILTER_ASSIGNED_LABEL: 'Assigned Courses',
    UDE_FILTER_INITIAL_LABEL: 'To Start',
    UDE_FILTER_PROGRESS_LABEL: 'In Progress',
    UDE_FILTER_COMPLETED_LABEL: 'Completed',
    UDE_FILTER_HIDDEN_LABEL: 'Hidden',
    // Empty view
    UDE_ALL_EMPTY_TITLE: "You don't have any enrolled courses yet",
    UDE_ALL_EMPTY_DESC: 'Explore our catalog and discover your first course.',
    UDE_GO_TO_CATALOG: 'Go to Catalog',
    UDE_ASSIGNED_EMPTY_TITLE: "You don't have any assigned courses yet",
    UDE_ASSIGNED_EMPTY_DESC:
      'Contact the administrator of your organization to start your learning journey.',
    UDE_INITIAL_EMPTY_TITLE: "Oops... You don't have any courses to start",
    UDE_INITIAL_EMPTY_DESC:
      "Don't fall behind! It's time to learn new skills with Crehana",
    UDE_PROGRESS_EMPTY_TITLE: 'Your courses are waiting for you',
    UDE_PROGRESS_EMPTY_DESC:
      "Everything alright? We see that you haven't started any of your enrolled courses on Crehana yet. It's time to learn!",
    UDE_PROGRESS_EMPTY_BUTTON: 'View courses to start',
    UDE_COMPLETED_EMPTY_TITLE: "Don't fall behind!",
    UDE_COMPLETED_EMPTY_DESC:
      "We see that you haven't completed any courses on Crehana yet. Keep going, you're almost there!\nDon't miss the opportunity to keep learning. We'll see you in class!",
    UDE_COMPLETED_EMPTY_BUTTON: 'View courses in progress',
    UDE_HIDDEN_EMPTY_TITLE: "You don't have any hidden courses",
    UDE_HIDDEN_EMPTY_DESC_1:
      'If you want to hide any of your courses, select the icon',
    UDE_HIDDEN_EMPTY_DESC_2: 'Once hidden, you can find it in this section.',
    UDE_HIDDEN_EMPTY_BUTTON: 'View all my courses',
    // Error view
    UDE_COURSES_ERROR: 'An error occurred while retrieving your courses',
    UDE_RECOMMENDED_COURSES_ERROR:
      'An error occurred while retrieving recommended courses',
    // Course card
    UDE_COURSE_PROGRESS: '{{progress}}% Completed',
    UDE_COURSE_COMPLETED: 'Course completed',
    UDE_HIDE_COURSE: 'Hide course',
    UDE_UNHIDE_COURSE: 'Unhide course',
    UDE_FREE_COURSE: 'Free course',
    UDE_FREE_ACCESS_COURSE: 'Free access',
    UDE_DOWNLOAD_DIPLOMA: 'Download diploma',

    // Card Lead
    CARD_LEAD_TITLE: 'Learn without limits',
    CARD_LEAD_COPY:
      'You are one call away from accessing the best discount on 800 courses, mentorships, specializations, and more.',
    CARD_LEAD_CTA: "Let's talk",

    // Card renewal
    CARD_RENEWAL_TITLE_WITH_NAME:
      '{{firstname}}, congratulations on your achievements this year!',
    CARD_RENEWAL_TITLE_WITHOUT_NAME:
      'Congratulations on your achievements this year!',
    CARD_RENEWAL_MESSAGE:
      'You have {{remainingDays}} more days to enjoy your {{membershipType}} membership. Shall we go for another year? Renew and keep growing.',
    CARD_RENEWAL_CTA: 'I want to renew',
  },
};

export default localeEn;
