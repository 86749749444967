import { useTranslation } from '@crehana/i18n';

import { COURSES_TRANSLATION_KEY } from '../constants';
import { CoursesLocalesBundle } from '../i18n';
import type { CoursesLocaleKeys } from '../types';

const useCoursesTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<CoursesLocaleKeys>(COURSES_TRANSLATION_KEY);

  addResourceBundle(CoursesLocalesBundle);

  return translationContextValue;
};

export default useCoursesTranslation;
