import type { TPageTranslations } from '@crehana/i18n';

import { COURSES_TRANSLATION_KEY } from '../constants';
import type { CoursesLocaleKeys } from '../types';

const localePt: TPageTranslations<
  CoursesLocaleKeys,
  typeof COURSES_TRANSLATION_KEY
> = {
  [COURSES_TRANSLATION_KEY]: {
    // Filters
    UDE_FILTER_ALL_TITLE: 'Você tem {{count}} curso no total',
    UDE_FILTER_ALL_TITLE_plural: 'Você tem {{count}} cursos no total',
    UDE_FILTER_ASSIGNED_TITLE: 'Você tem {{count}} curso atribuído',
    UDE_FILTER_ASSIGNED_TITLE_plural: 'Você tem {{count}} cursos atribuídos',
    UDE_FILTER_INITIAL_TITLE: 'Você tem {{count}} curso para começar',
    UDE_FILTER_INITIAL_TITLE_plural: 'Você tem {{count}} cursos para começar',
    UDE_FILTER_PROGRESS_TITLE: 'Você tem {{count}} curso em progresso',
    UDE_FILTER_PROGRESS_TITLE_plural: 'Você tem {{count}} cursos em progresso',
    UDE_FILTER_COMPLETED_TITLE: 'Você tem {{count}} curso concluído',
    UDE_FILTER_COMPLETED_TITLE_plural: 'Você tem {{count}} cursos concluídos}',
    UDE_FILTER_HIDDEN_TITLE: 'Você tem {{count}} curso oculto',
    UDE_FILTER_HIDDEN_TITLE_plural: 'Você tem {{count}} cursos ocultos',
    UDE_FILTER_ALL_LABEL: 'Todos',
    UDE_FILTER_ASSIGNED_LABEL: 'Cursos atribuídos',
    UDE_FILTER_INITIAL_LABEL: 'Para começar',
    UDE_FILTER_PROGRESS_LABEL: 'Em progresso',
    UDE_FILTER_COMPLETED_LABEL: 'Concluídos',
    UDE_FILTER_HIDDEN_LABEL: 'Ocultos',
    // Empty view
    UDE_ALL_EMPTY_TITLE: 'Você ainda não possui cursos inscritos',
    UDE_ALL_EMPTY_DESC: 'Explore nosso catálogo e descubra seu primeiro curso',
    UDE_GO_TO_CATALOG: 'Ir para o catálogo',
    UDE_ASSIGNED_EMPTY_TITLE: 'Você ainda não tem cursos atribuídos',
    UDE_ASSIGNED_EMPTY_DESC:
      'Entre em contato com o administrador da sua  empresa para iniciar seu aprendizado.',
    UDE_INITIAL_EMPTY_TITLE: 'Poxa... Você não tem cursos para começar',
    UDE_INITIAL_EMPTY_DESC:
      'Não fique para trás! É hora de aprender novas habilidades com Crehana',
    UDE_PROGRESS_EMPTY_TITLE: 'Seus cursos esperam por você',
    UDE_PROGRESS_EMPTY_DESC:
      'Tudo bem? Vimos que você ainda não iniciou nenhum dos seus cursos matriculados na Crehana. É hora de aprender!',
    UDE_PROGRESS_EMPTY_BUTTON: 'Ir aos cursos',
    UDE_COMPLETED_EMPTY_TITLE: 'Não fique para trás!',
    UDE_COMPLETED_EMPTY_DESC:
      'Vemos que você ainda não completou nenhum curso na Crehana. Vamos lá, não vai demorar!\nNão perca a oportunidade de continuar aprendendo, estamos esperando por você nas aulas!',
    UDE_COMPLETED_EMPTY_BUTTON: 'Ver cursos em progresso',
    UDE_HIDDEN_EMPTY_TITLE: 'Você não tem cursos ocultos',
    UDE_HIDDEN_EMPTY_DESC_1:
      'Caso queira ocultar algum de seus cursos, selecione o ícone',
    UDE_HIDDEN_EMPTY_DESC_2:
      'Uma vez oculto, você pode encontrá-los denovo nesta seção.',
    UDE_HIDDEN_EMPTY_BUTTON: 'Ver todos meus cursos',
    // Error view
    UDE_COURSES_ERROR: 'Ocorreu um erro ao obter seus cursos',
    UDE_RECOMMENDED_COURSES_ERROR:
      'Ocorreu um erro ao obter os cursos recomendados',
    // Course card
    UDE_COURSE_PROGRESS: '{{progress}}% Concluído',
    UDE_COURSE_COMPLETED: 'Curso concluído',
    UDE_HIDE_COURSE: 'Ocultar curso',
    UDE_UNHIDE_COURSE: 'Remover do ocultos',
    UDE_FREE_COURSE: 'Curso grátis',
    UDE_FREE_ACCESS_COURSE: 'Acesso livre',
    UDE_DOWNLOAD_DIPLOMA: 'Baixar diploma',

    // Card Lead
    CARD_LEAD_TITLE: 'Aprenda sem limites',
    CARD_LEAD_COPY:
      'Está perto de acessar o melhor desconto em 500 cursos, mentorias, especializações e mais.',
    CARD_LEAD_CTA: 'Vamos conversar',

    // Card renewal
    CARD_RENEWAL_TITLE_WITH_NAME:
      '{{firstname}}, parabéns pelas conquistas deste ano!',
    CARD_RENEWAL_TITLE_WITHOUT_NAME: 'Parabéns pelas conquistas deste ano!',
    CARD_RENEWAL_MESSAGE:
      'Você tem {{remainingDays}} dias a mais para aproveitar sua assinatura de {{membershipType}}. Vamos por mais 1 ano? Renove e continue crescendo.',
    CARD_RENEWAL_CTA: 'Quero renovar',
  },
};

export default localePt;
