import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import LEARN_DASHBOARD_MEMBERSHIP_QUERY from './graphql/LearnDashboardMembershipQuery.graphql';
import {
  ClassroomMembershipQuery as ClassroomMembershipQueryResponse,
  ClassroomMembershipQueryVariables,
} from './graphql/types/ClassroomMembershipQuery';

const useMembershipData = (options?: { skipQuery?: boolean }) => {
  const [getMembershipData, { data, loading }] = useLazyQuery<
    ClassroomMembershipQueryResponse,
    ClassroomMembershipQueryVariables
  >(LEARN_DASHBOARD_MEMBERSHIP_QUERY, {
    variables: {
      group: 'MEMBERSHIP_PRICING_LANDING_KEY',
      tag: 'PREMIUM_DUO',
    },
  });

  useEffect(() => {
    if (!options?.skipQuery) {
      getMembershipData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.skipQuery]);

  const currencySymbol = data?.membershipType?.prices?.currencySymbol;
  const price = data?.membershipType?.prices?.total;
  const monthlyPrice = data?.membershipType?.prices?.monthlyPrice;

  return {
    membership: data?.membershipType?.prices
      ? {
          price:
            currencySymbol && price ? `${currencySymbol}${price}` : undefined,
          monthlyPrice:
            currencySymbol && monthlyPrice
              ? `${currencySymbol}${monthlyPrice}`
              : undefined,
          checkoutUrl: data?.membershipType?.urlCheckout,
          discount: data?.membershipType?.discount,
        }
      : undefined,
    loading,
  };
};

export default useMembershipData;
