import type { CoursesLocaleKeys } from '@/shared/components';

export const THRESHOLD_REMAINING_DAYS = 60;
export const COURSES_BY_PAGE = 12;
export const COURSES_TRANSLATION_KEY = 'learn-dashboard-courses';
export const ENROLLMENT_URL_SOURCES = {
  RECOMMENDED_COURSES: 'Recommended Courses',
} as const;

export const FILTER_TITLE_KEYS: Readonly<Record<string, CoursesLocaleKeys>> = {
  all: 'UDE_FILTER_ALL_TITLE',
  assigned: 'UDE_FILTER_ASSIGNED_TITLE',
  initial: 'UDE_FILTER_INITIAL_TITLE',
  progress: 'UDE_FILTER_PROGRESS_TITLE',
  completed: 'UDE_FILTER_COMPLETED_TITLE',
  hidden: 'UDE_FILTER_HIDDEN_TITLE',
};
