import { useState } from 'react';

import { DataProxy, useMutation } from '@apollo/client';

import { useNewDashboardContext } from '@crehana/web';
import COURSE_ENROLLMENTS_QUERY from '@crehana/web/Graphql/Queries/CourseEnrollmentsQuery.graphql';

import TOGGLE_HIDDEN_COURSE_MUTATION from '../graphql/ToggleHiddenUserDashboardCourse.graphql';
import {
  ToggleHiddenUserDashboardCourse as ToggleHiddenCourseResponse,
  ToggleHiddenUserDashboardCourseVariables as ToggleHiddenCourseVariables,
} from '../graphql/types/ToggleHiddenUserDashboardCourse';
import {
  CourseEnrollmentsQueryResponse,
  CourseEnrollmentsQueryVariables,
  EnrollmentNodeEdge,
} from './useCourseEnrollments/types';

const useToggleHiddenCourse = ({
  currentQueryVars,
}: {
  currentQueryVars?: CourseEnrollmentsQueryVariables;
}) => {
  const [toggleHiddenCourseMutation] = useMutation<
    ToggleHiddenCourseResponse,
    ToggleHiddenCourseVariables
  >(TOGGLE_HIDDEN_COURSE_MUTATION);
  const { openErrorSnackbar } = useNewDashboardContext();

  const [enrollmentsLoadingHiddenState, setEnrollmentsLoadingHiddenState] =
    useState<number[]>([]);

  const removeEnrollmentFromQuery = ({
    cache,
    cacheData,
    variables,
    enrollmentId,
  }: {
    cache: DataProxy;
    cacheData: CourseEnrollmentsQueryResponse;
    variables: CourseEnrollmentsQueryVariables;
    enrollmentId: number | null;
  }) => {
    if (cacheData.me?.enrollmentSet) {
      cache.writeQuery<
        CourseEnrollmentsQueryResponse,
        CourseEnrollmentsQueryVariables
      >({
        query: COURSE_ENROLLMENTS_QUERY,
        variables,
        data: {
          ...cacheData,
          me: {
            ...cacheData.me,
            enrollmentSet: {
              ...cacheData.me.enrollmentSet,
              totalCount: (cacheData.me.enrollmentSet.totalCount || 1) - 1,
              edges: cacheData.me.enrollmentSet.edges.filter(
                enrollment => enrollment?.node?.originalId !== enrollmentId,
              ),
            },
          },
        },
      });
    }
  };

  const addEnrollmentToQuery = ({
    cache,
    cacheData,
    variables,
    enrollmentId,
    isHidden,
  }: {
    cache: DataProxy;
    cacheData: CourseEnrollmentsQueryResponse;
    variables: CourseEnrollmentsQueryVariables;
    enrollmentId: number | null;
    isHidden: boolean;
  }) => {
    if (cacheData.me?.enrollmentSet) {
      const enrollmentNodeEdge: EnrollmentNodeEdge | null | undefined =
        cacheData.me.enrollmentSet.edges.find(
          item => item?.node?.originalId === enrollmentId,
        );

      if (enrollmentNodeEdge) {
        cache.writeQuery<
          CourseEnrollmentsQueryResponse,
          CourseEnrollmentsQueryVariables
        >({
          query: COURSE_ENROLLMENTS_QUERY,
          variables,
          data: {
            ...cacheData,
            me: {
              ...cacheData.me,
              enrollmentSet: {
                ...cacheData.me.enrollmentSet,
                totalCount: (cacheData.me.enrollmentSet.totalCount || 0) + 1,
                edges: [
                  {
                    ...enrollmentNodeEdge,
                    node: {
                      ...enrollmentNodeEdge.node!,
                      isHidden,
                    },
                  },
                  ...cacheData.me.enrollmentSet.edges,
                ],
              },
            },
          },
        });
      }
    }
  };

  const toggleHiddenCourse = ({
    enrollmentId,
    courseProgress,
    isAssigned,
  }: {
    enrollmentId: number;
    courseProgress: number;
    isAssigned: boolean;
  }) => {
    setEnrollmentsLoadingHiddenState(prevState => [...prevState, enrollmentId]);
    toggleHiddenCourseMutation({
      variables: { enrollmentId },
      update: (cache, res) => {
        const success = res.data?.toggleEnrollment?.success;
        const isHidden = res.data?.toggleEnrollment?.isHidden;

        if (success) {
          let allCoursesCacheData: CourseEnrollmentsQueryResponse | null;
          let filteredCoursesCacheData: CourseEnrollmentsQueryResponse | null;
          let hiddenCoursesCacheData: CourseEnrollmentsQueryResponse | null;

          try {
            const allCoursesVariables: CourseEnrollmentsQueryVariables = {
              ...currentQueryVars,
              isHidden: false,
              progressState: undefined,
              assignedCourses: null,
            };

            allCoursesCacheData = cache.readQuery<
              CourseEnrollmentsQueryResponse,
              CourseEnrollmentsQueryVariables
            >({
              query: COURSE_ENROLLMENTS_QUERY,
              variables: allCoursesVariables,
            });

            if (allCoursesCacheData) {
              if (isHidden) {
                // Remove enrollment from the main courses query
                removeEnrollmentFromQuery({
                  cache,
                  cacheData: allCoursesCacheData,
                  variables: allCoursesVariables,
                  enrollmentId,
                });
              } else {
                // Add enrollment to the main courses query
                addEnrollmentToQuery({
                  cache,
                  cacheData: allCoursesCacheData,
                  variables: allCoursesVariables,
                  enrollmentId,
                  isHidden: false,
                });
              }
            }
          } catch (e) {
            // The main courses query hasn't been called yet
          }
          try {
            let progressFilter: 'initial' | 'progress' | 'completed' =
              courseProgress > 0 ? 'progress' : 'initial';

            if (courseProgress >= 100) {
              progressFilter = 'completed';
            }
            const filteredCoursesVariables: CourseEnrollmentsQueryVariables = {
              ...currentQueryVars,
              isHidden: false,
              progressState: !isAssigned ? progressFilter : undefined,
              assignedCourses: isAssigned ? true : null,
            };

            filteredCoursesCacheData = cache.readQuery<
              CourseEnrollmentsQueryResponse,
              CourseEnrollmentsQueryVariables
            >({
              query: COURSE_ENROLLMENTS_QUERY,
              variables: filteredCoursesVariables,
            });

            if (filteredCoursesCacheData) {
              if (isHidden) {
                // Remove enrollment from the corresponding filtered query
                removeEnrollmentFromQuery({
                  cache,
                  cacheData: filteredCoursesCacheData,
                  variables: filteredCoursesVariables,
                  enrollmentId,
                });
              } else {
                // Add enrollment to the corresponding filtered query
                addEnrollmentToQuery({
                  cache,
                  cacheData: filteredCoursesCacheData,
                  variables: filteredCoursesVariables,
                  enrollmentId,
                  isHidden: false,
                });
              }
            }
          } catch (e) {
            // The corresponding filtered query hasn't been called yet
          }
          try {
            const hiddenCoursesVariables: CourseEnrollmentsQueryVariables = {
              ...currentQueryVars,
              isHidden: true,
              progressState: undefined,
              assignedCourses: null,
            };

            hiddenCoursesCacheData = cache.readQuery<
              CourseEnrollmentsQueryResponse,
              CourseEnrollmentsQueryVariables
            >({
              query: COURSE_ENROLLMENTS_QUERY,
              variables: hiddenCoursesVariables,
            });

            if (hiddenCoursesCacheData) {
              if (isHidden) {
                // Add enrollment to the hidden courses query
                addEnrollmentToQuery({
                  cache,
                  cacheData: hiddenCoursesCacheData,
                  variables: hiddenCoursesVariables,
                  enrollmentId,
                  isHidden: true,
                });
              } else {
                // Remove enrollment from the hidden courses query
                removeEnrollmentFromQuery({
                  cache,
                  cacheData: hiddenCoursesCacheData,
                  variables: hiddenCoursesVariables,
                  enrollmentId,
                });
              }
            }
          } catch (e) {
            // The hidden courses query hasn't been called yet
          }
        } else {
          openErrorSnackbar();
        }
        setEnrollmentsLoadingHiddenState(prevState =>
          prevState.filter(id => id !== enrollmentId),
        );
      },
    }).catch(() => {
      openErrorSnackbar();
      setEnrollmentsLoadingHiddenState(prevState =>
        prevState.filter(id => id !== enrollmentId),
      );
    });
  };

  return { toggleHiddenCourse, enrollmentsLoadingHiddenState };
};

export default useToggleHiddenCourse;
